// Convert kebab-case to camelCase: my-page-asset > myPageAsset
export const camelize = s => s.replace(/-(.)/g, l => l[1].toUpperCase());

export const camelCaseToTitleCase = s => {
  const result = s.replace(/([A-Z])/g, " $1");
  return ucfirst(result);
};

export const ucfirst = s => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isNotEmptyString = s => {
  return typeof s === 'string' && s.trim().length > 0;
};

export const nextInArray = (item, items, force = false) => {
  const currentIndex = items.indexOf(item);
  if (force && currentIndex < 0) {
    throw new Error('Item not found in the array');
  }
  const nextIndex = currentIndex + 1;
  return nextIndex < items.length ? items[nextIndex] : (items[currentIndex] || items[0]);
};
