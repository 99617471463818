import { GROUPS_NAME, STATUS } from './userHelpers';

export const ASSOCIATED = 'associated';
export const OWNER = 'owner';

export const listingAttached = (listing, user, searchAssociated = false) => {
  const authorId = listing?.relationships?.author?.data?.id?.uuid;
  const { creatorId, teamId } = listing?.attributes?.publicData || {};

  const userId = user?.id?.uuid;
  const userPublicData = user?.attributes?.profile?.publicData || {};
  const groups = userPublicData[GROUPS_NAME] || [];

  let isAuthor = userId === authorId;
  let isCreator = userId === creatorId;

  let group = groups.find(g => g.id === teamId && g.status === STATUS.ACTIVE);

  let isAssociated = group && isCreator;

  return searchAssociated ? isAssociated : (isAuthor || isAssociated);
};

export const transactionAttached = (transaction, user) => {
  const { isAssociated, creatorId } = transaction?.attributes?.protectedData || {};
  const authorId = transaction?.provider?.id?.uuid;
  const userId = user?.id?.uuid;

  let isAuthor = userId === authorId;
  let isCreator = userId === creatorId;

  return isAssociated && (isAuthor || isCreator);
};

export const getDataFromState = (getState, listingId) => {
  let state = getState();
  let listings = state?.marketplaceData?.entities?.ownListing || {};
  let listing = listings[listingId.uuid] !== undefined ? listings[listingId.uuid] : null;
  let currentUser = state?.user?.currentUser;

  return { listing, currentUser };
};

export const getOwnerLabel = (tx, currentUser, returnLabelAndConditional = false) => {
  const { creatorId, creatorType, isAssociated } = tx.attributes?.protectedData || {};
  let userId = currentUser?.id?.uuid;
  const isOwner = isAssociated && userId == creatorId;
  const fromAssociated = creatorType == ASSOCIATED;

  let showCreator = false;

  let ownerLabel;
  if (!isOwner && !fromAssociated) {
    ownerLabel = "InboxPage.ownerIsCreator";
    showCreator = true;
  } else if (!isOwner && fromAssociated) {
    ownerLabel = "InboxPage.associatedIsCreator";
    showCreator = true;
  } else if (isOwner && !fromAssociated) {
    ownerLabel = "InboxPage.currentUserIsCreatorAsOwner";
  } else if (isOwner && fromAssociated) {
    ownerLabel = "InboxPage.currentUserIsCreatorAsAssociated";
  } else {
    ownerLabel = "InboxPage.currentUserIsCreator";
  }

  return returnLabelAndConditional ? { ownerLabel, showCreator } : ownerLabel;
};

export const filterCompletedListings = (listings = []) => {
  if (Array.isArray(listings) && listings.length) {
    listings = listings.filter(l => {
      const { price, publicData = {} } = l.attributes || {};
      let hasPrice = !!price;
      let images = l?.relationships?.images?.data || [];
      let hasImages = !!images.length;

      let hasDeliveryOption = publicData?.deliveryPreference !== undefined;

      const optionalPublicData = [
        'shippingAddress',
        'location',
        'storedAddress'
      ];

      const obtainedParams = optionalPublicData.filter(f => publicData[f] != undefined);
      let hasDelivery = hasDeliveryOption && !!obtainedParams.length;

      let isDraft = publicData?.isAssociated && (!hasPrice || !hasImages || !hasDelivery);
      return !isDraft;
    });
  }

  return listings;
};
